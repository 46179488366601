.table-box table thead tr th:after {
  content: "";
  border: 1px solid #d9a91d;
  position: absolute;
  right: -2px;
  top: 0px;
  height: 100%;
}

.table-box table thead tr th:last-child:after {
  display: none;
}

.tb-slct select {
  width: 165px;
  margin-top: 14px;
}

.horizontal-menu
  .top-navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-profile-img
  h5 {
  background-color: #ccc;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 25px;
  text-align: center;
  padding: 8px 14px;
  margin-bottom: 0px;
}

/* voucher-html*/

.in-head-box img {
  width: 11rem;
}
.head-main-box {
  /* border: 1px solid #333; */
  display: flex;
  justify-content: space-between;
 
  /* margin: 0 auto;
  border-bottom: none; */
  align-items: center;
  padding: 10px;
}
.in-head-box.text-center h6 {
  font-size: 12px;
  padding-top: 4px;
}
.head-section {
  padding: 20px 0px 20px;
}
.in-head-box.text-center a {
  font-size: 12px;
  color: #000;
  text-decoration: none;
  padding: 0px;
}
.table-box table thead {
  background: #d9a91d;
}
.row-1 {
  display: flex;
}
.table-box table {
  margin-bottom: 0;
  border-bottom: 0px;
  border: 1px solid #000;
}
.row-1 .form-control {
  border: none;
  border-bottom: 1px solid;
  border-radius: 0px;
  padding: 0px 0px;
  margin-left: 0.5rem;
  background: transparent;
  height: 26px;
}
.row-1 h5 {
  font-size: 14px;
  font-weight: 700;
}
.row-1 .form-label {
  margin-bottom: 0rem;
  font-size: 16px;
  font-style: italic;
}
.form-control:focus {
  box-shadow: none;
}
.row-2.d-flex.justify-content-between {
  border: 1px solid;
  padding: 10px 15px 7px;
}
.table.table-2 .tbody td {
  border: 1px solid;
}
.table.table-2 tbody tr th {
  width: 15rem;
}
.table-box table td {
  border: 1px solid;
}
.foot-description p {
  font-size: 14px;
}
.foot-description {
  padding-top: 0.5rem;
}
.foot-box {
  background: #d9a91d;
  padding: 5px 0px 5px;
  text-align: center;
  margin-top: 16px;
}
.foot-box p {
  margin-bottom: 0;
}
.foot-box p a {
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}
.table-box table thead tr th {
  border: 1px solid;
  position: relative;
}
.head-section .row {
  margin: 0rem 2rem;
  box-shadow: 0px 0px 9px;
  padding: 1rem 1rem;
  margin-top: 65px;
  margin-bottom: 75px;
}

.table-box .table-1 tr td {
  line-height: 20px;
}

@media (max-width: 579px) and (min-width: 280px) {
}


