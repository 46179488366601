.modal-backdrop {
  background-color: #00000096;
}

.search input {
  height: 30px;
}

.entries input {
  width: 80px;
  height: 30px;
}
.filters {
  padding: 18px;
}

.filters .form-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
}

.filters label {
  width: 50%;
}

.filters select {
  height: 30px;
}

.form-horizontal .form-group .input {
  display: grid;
  grid-auto-flow: column;
  position: relative;
}

.form-horizontal .form-group .input img {
  position: absolute;
  width: 80px;
  right: 12px;
  padding: 6px 0px;
  height: 48px;
}

.block-flex {
  display: flex;
  width: 100%;
  position: relative;
}

.form-group {
  width: 100%;
}

.block-flex span {
  position: absolute;
  padding: 0.09rem 0.5625rem;
  right: 1.5rem;
}

.lot .status .modal {
  display: none !important;
}
.lot .status .modal-backdrop {
  background: transparent;
  display: none !important;
}

.track .modal-header {
  display: block;
}

.track .modal-header h4 {
  text-align: center;
  font-size: 25px;
}

.track .modal .modal-dialog .modal-content .modal-body {
  padding: 20px 20px;
}

.track .modal-header .close {
  padding: 0px;
  margin: 0px;
}

.track .modal-dialog {
  max-width: 1000px;
  margin: 30px auto;
}

.track .card {
  width: 35%;
}

.timeline-1 h4 {
  position: absolute;
  left: 340px !important;
  width: 88%;
}

.main-timeline {
  width: 65%;
  margin-right: 10px;
  overflow: scroll;
  overflow-x: hidden;
  min-height: 480px;
  max-height: 480px;
}

.timeline h4 {
  position: absolute;
  left: -158px;
  top: 65px;
  color: #d9a91d;
}

.main-timeline:before {
  content: "";
  background-color: #e7e7e7;
  height: 100%;
  width: 15px;
  border-radius: 10px;
  transform: translateX(-50%);
  position: absolute;
  left: 32%;
  top: 0;
}
.main-timeline:after {
  content: "";
  display: block;
  clear: both;
}
.main-timeline .inner-content label {
  display: block;
}
.main-timeline .timeline {
  width: 50%;
  padding: 0 0 0 10px;
  margin: 0 0 20px 0px;
  float: right;
  position: relative;
}
.main-timeline .timeline:after {
  content: "";
  background-color: #d9a91d;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  box-shadow: 0 0 0 3px #fff, 0 0 10px #333;
  transform: translateY(-50%);
  position: absolute;
  left: -7px;
  top: 50%;
}
.main-timeline .timeline-content {
  /* color: #555; */
  /* background: linear-gradient(to right,#d9a91d,#d9a91d87 22%); */
  padding: 0px;
  display: block;
  position: relative;
  /* clip-path: polygon(7% 0, 100% 0, 100% 100%, 7% 100%, 0 50%); */
  margin-left: 12px;
}
.main-timeline .timeline-content:hover {
  text-decoration: none;
}
.main-timeline .timeline-icon {
  color: #fff;
  font-size: 35px;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: 18px;
}
.main-timeline .inner-content {
  color: #555;
  background-color: #fff;
  min-height: 110px;
  padding: 10px 10px 10px 10px;
  position: relative;
  border-radius: 0px 25px 0px 25px;
}
/*.main-timeline .inner-content:before {
content: '';
background-color: #fff;
height: 101%;
width: 65px;
border-radius: 50%;
transform: translateY(-50%);
position: absolute;
left: -37px;
top: 50%;
z-index: -1;
}*/
.main-timeline .title {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 0 8px;
}
.main-timeline .description {
  font-size: 13px;
  letter-spacing: 1px;
  margin: 0;
  line-height: 18px;
}
.main-timeline .timeline:nth-child(even) {
  padding: 0 10px 0 0;
  margin: 0 10px 30px 0;
  float: left;
}
.main-timeline .timeline:nth-child(even):after {
  left: auto;
  right: -8px;
}
.main-timeline .timeline:nth-child(even) .timeline-content {
  padding: 0px;
  /* clip-path: polygon(93% 0, 100% 50%, 93% 100%, 0 100%, 0 0); */
  margin-right: 12px;
}
.main-timeline .timeline:nth-child(even) .timeline-icon {
  left: auto;
  right: 20px;
}
.main-timeline .timeline:nth-child(even) .inner-content {
  padding: 10px 10px 10px 10px;
}
.main-timeline .timeline:nth-child(even) .inner-content:before {
  left: auto;
  right: -37px;
}
.main-timeline .timeline:nth-child(4n + 2):after {
  background-color: #d9a91d;
}
.main-timeline .timeline:nth-child(4n + 2) .timeline-content {
  /* background: linear-gradient(to left,#d9a91d,#d9a91d87 22%); */
}
.main-timeline .timeline:nth-child(4n + 3):after {
  background-color: #d9a91d;
}
.main-timeline .timeline:nth-child(4n + 3) .timeline-content {
  /*background: linear-gradient(to right,#4e5db2,#7582D1 22%);*/
}
.main-timeline .timeline:nth-child(4n + 4):after {
  background-color: #d9a91d;
}
.main-timeline .timeline:nth-child(4n + 4) .timeline-content {
  /* background: linear-gradient(to left,#d9a91d,#d9a91d87  22%);*/
}

.branch-select label {
  display: none;
}

@media screen and (max-width: 767px) {
  .main-timeline:before {
    left: 19px;
    transform: translateX(0);
  }
  .main-timeline .timeline,
  .main-timeline .timeline:nth-child(even) {
    width: 100%;
    margin: 0 0 30px;
    padding: 0 0 0 25px;
  }
  .main-timeline .timeline:after,
  .main-timeline .timeline:nth-child(even):after {
    left: 3px;
  }
  .main-timeline .timeline-icon,
  .main-timeline .timeline:nth-child(even) .timeline-icon {
    font-size: 30px;
    left: 23px;
  }
  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(even) .timeline-content {
    padding: 0 0 0 90px;
    clip-path: polygon(7% 0, 100% 0, 100% 100%, 7% 100%, 0 50%);
  }
  .main-timeline .inner-content:before,
  .main-timeline .timeline:nth-child(even) .inner-content:before {
    width: 50px;
    left: -25px;
  }
}
@media screen and (max-width: 567px) {
  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(even) .timeline-content {
    padding: 0 0 0 65px;
    clip-path: polygon(5% 0, 100% 0, 100% 100%, 5% 100%, 0 50%);
  }
  .main-timeline .timeline-icon,
  .main-timeline .timeline:nth-child(even) .timeline-icon {
    font-size: 21px;
    left: 9px;
  }
  .main-timeline .title {
    font-size: 18px;
  }
}

.table-lot tbody tr:nth-child(even) td {
  padding: 0;
}

.tabList ul li button {
  font-size: 16px;
  line-height: 14px;
}

.tabList ul li button.active {
  color: #fff !important;
  background-color: #ddb23a !important;
}
