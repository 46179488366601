.logine {
  border-radius: 25px;
  max-width: 340px;
  margin: 0 auto;
  padding: 20px 20px 0;
  border: 1px solid #e1dcdc;
  padding-bottom: 20px;
  background-color: #fff;
  /*box-shadow: 0px 0px 4px 2px #00a1e040;*/
  box-shadow: 0 0 5px 1px #e2e2e2;
  position: relative;
}
.logine label {
  font-size: 14px;
  font-weight: 400;
  color: #212529;
}
label.form-check-label {
  margin-top: 10px;
  margin-bottom: 20px;
}
form.logine .form-control {
  height: 38px;
  border-radius: 14px;
}
.logine button {
  padding: 7px 124px;
  background-color: #d9a91d;
  border-color: #d9a91d;
  margin-top: 15px;
  border-radius: 14px;
  color: #fff;
}

.logine button:hover {
  background-color: #d9a91d;
  color: #fff;
}
