.tb-slct select {
  width: 165px;
  margin-top: 14px;
}
.horizontal-menu
  .top-navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-profile-img
  h5 {
  background-color: #ccc;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 25px;
  text-align: center;
  padding: 8px 14px;
  margin-bottom: 0px;
}
.btn-bk {
  padding: 10px 40px;
  background: linear-gradient(to right, #d9a91d, #d9a91d87) !important;
  border-radius: 0.1875rem;
  color: #fff;
  font-size: 0.875rem;
  line-height: 1;
  font-family: "ubuntu-bold", sans-serif;
}
.customer-main {
  justify-content: space-between;
}
.customer-left p {
  margin-bottom: 5px;
  line-height: 18px;
  padding-left: 10px;
}
.customer-right p {
  margin-bottom: 5px;
  line-height: 18px;
  padding-left: 10px;
}

.prdct-tbs {
  padding-top: 20px;
}

.customer-left {
  align-items: center;
  width: 75%;
}

.customer-left .form-group {
  padding: 16px;
  width: 250px !important;
}
.customer-left .form-group select {
  height: 30px;
}
.customer-right {
  align-items: center;
}
.invoice-table table thead tr th:nth-child(6),
.invoice-table table thead tr th:nth-child(7) {
  width: 100px;
}

.invoice-table table tbody tr td:nth-child(6) input,
.invoice-table table tbody tr td:nth-child(7) input,
.invoice-table table tbody tr td:nth-child(6) select,
.invoice-table table tbody tr td:nth-child(7) select {
  width: 80px;
}
