.tb-slct select {
  width: 165px;
  margin-top: 14px;
}

.horizontal-menu
  .top-navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-profile-img
  h5 {
  background-color: #ccc;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 25px;
  text-align: center;
  padding: 8px 14px;
  margin-bottom: 0px;
}

.form-sample {
  border: 1px solid #ccc;
  padding: 20px 20px;
}

.card .card-description {
  border-bottom: 1px solid #76838f;
  padding-bottom: 10px;
  font-weight: 600;
  color: #000;
}

.btn-svs {
  background-color: #d9a91d;
  color: #fff;
  width: 130px;
  margin-right: 10px;
}

.btn-cnc {
  background-color: #000;
  color: #fff;
  width: 130px;
}

select.form-control {
  outline-color: #c8cbd1;
}

.form-control {
  border-color: #c8cbd1;
}

.form-check {
  display: inline-block;
  margin-right: 3px;
}

.wrap {
  width: 160px;
  border-radius: 4px;
  background-color: #000;
  margin-bottom: 10px;
}

.file {
  position: relative;
  max-width: 20.5rem;
  font-size: 14px;
  font-weight: 600;
}

.file__input,
.file__value {
  background-color: rgba(255, 255, 255, 0.1);
  background-color: rgb(217 169 29 / 40%);
  border-radius: 3px;
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.3);
  padding: 5px 5px;
}

.file__input--file {
  position: absolute;
  opacity: 0;
}

.file__input--label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
}

.file__input--label:after {
  content: attr(data-text-btn);
  border-radius: 3px;
  background-color: #d9a91d;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18);
  padding: 8px 52px;
  margin: 0px;
  color: white;
  cursor: pointer;
}

.file__value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);
}

.file__value:hover:after,
.file__value:after:hover {
  color: white;
}

.file__value:after {
  content: "";
  cursor: pointer;
}

.file__remove {
  display: block;
  width: 18px;
  height: 18px;
  border: 1px solid #000;
}

a.add-btns:hover {
  color: #fff;
  text-decoration: none;
}
