.tb-slct select {
  width: 165px;
  margin-top: 14px;
}
.horizontal-menu
  .top-navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-profile-img
  h5 {
  background-color: #ccc;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 25px;
  text-align: center;
  padding: 8px 14px;
  margin-bottom: 0px;
}
.form-sample {
  border: 1px solid #ccc;
  padding: 20px 20px;
}
.card .card-description {
  border-bottom: 1px solid #76838f;
  padding-bottom: 10px;
  font-weight: 600;
  color: #000;
}
.btn-svs {
  background-color: #d9a91d;
  color: #fff;
  width: 130px;
  margin-right: 10px;
}
.btn-cnc {
  background-color: #000;
  color: #fff;
  width: 130px;
}
select.form-control {
  outline-color: #c8cbd1;
}
.form-control {
  border-color: #c8cbd1;
}

.form-check {
  display: inline-block;

  margin-right: 3px;
}

.form-sample .table-select thead th {
  vertical-align: baseline;
}

.form-sample .table-select input,
.form-sample .table-select select {
  width: 150px;
}

.table-select .searchWrapper {
  min-height: 44px;
  width: 100%;
  white-space: break-spaces;
}

.table-select .multiSelectContainer ul {
  /* overflow: hidden; */
  width: max-content;
}

.table-select .chip {
  font-size: 14px;
  line-height: 14px;
  padding: 2px 4px;
}
.table-select .chip img {
  width: 22px;
  height: 22px;
}
