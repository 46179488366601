b,
strong {
  font-size: 13px;
  line-height: 19px;
}

th {
  font-size: 14px;
}

td {
  font-size: 14px;
  font-weight: 400;
}

.invoice-table-2 > thead {
  border-color: #000;
}

.main-invoice {
  margin: 0px 8rem;
  padding: 10px 10px;
  background-color: #fff;
}
.invoice-header img {
  width: 250px;
}
.invoice-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  padding: 0px 20px;
}

.invoice-top {
  background-color: #ebc139;
  width: 100%;
  height: 4vh;
  margin-bottom: 25px;
}
.invoice-header h6 {
  margin-bottom: 0px;
  margin-top: 4px;
}
.invoice-table-1 h3 {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 15px;
}

.term-box {
  display: flex;
  justify-content: space-between;
}

.term-box-left {
  width: 50%;
  padding: 5px 0px;
  position: relative;
}

.term-box-right {
  width: 50%;
  border-left: 1px solid #dee2e6;
  text-align: center;
  padding-top: 25px;
}

.term-box {
  border: 1px solid #dee2e6;
  border-top: none;
  padding: 0px 8px;
}

.signtur label:last-child {
  border: 1px solid #333;
  width: 160px;
  position: absolute;
  bottom: 0;
  margin-left: 10px;
}

.signtur {
  position: relative;
  padding-top: 120px;
}

.term-box-left h6 {
  margin-bottom: 0px;
  color: #000;
}

.term-box-right h6 {
  margin-bottom: 0px;
  font-size: 15px;
  color: #000;
}

.term-box-left p {
  margin-bottom: 8px;
  font-size: 14px;
  color: #000;
}

.signtr-req p {
  font-size: 12px;
  text-align: center;
  margin-bottom: 0px;
  border: 1px solid #dee2e6;
  border-top: none;
  padding: 8px 0px;
  color: #000;
}

.invoic-footer h4 {
  font-size: 16px;
  margin-bottom: 4px;
  font-weight: 700;
}

.invoic-footer p {
  font-size: 14px;
  padding: 0px 75px;
  line-height: 18px;
  margin-bottom: 0px;
  font-weight: 600;
}

.invoic-footer {
  background-color: #ebc139;
  padding: 10px;
  text-align: center;
}

.main-invoice table td {
  white-space: break-spaces;
}

hr {
  position: absolute;
  left: -9px;
  top: 131px;
  width: 102.7%;
  border-color: #ffffff57;
}

body {
  background-color: #d4d4d7;
}
