.footer {
  background: #f2edf3;
  border-top: 1px solid #e7dee9;
  padding: 15px 1rem;
  transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  font-size: calc(0.875rem - 0.05rem);
  font-family: "ubuntu-regular", sans-serif;
}

.text-muted,
.preview-list .preview-item .preview-item-content p .content-category,
.email-wrapper
  .mail-sidebar
  .menu-bar
  .profile-list-item
  a
  .user
  .u-designation,
.email-wrapper .mail-list-container .mail-list .content .message_text,
.email-wrapper .mail-list-container .mail-list .details .date {
  color: #9c9fa6 !important;
}
.footer a {
  color: #dbad28;
  font-size: inherit;
}
