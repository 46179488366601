.jewellerycert {
  margin: 15px 6rem;
  background-color: #fff;
  padding: 35px 25px;
  display: inline-block;
}

.jewellerycert-box-left-hd {
  display: flex;
  justify-content: space-between;
}

.jewellerycert-box-left-hd img {
  width: 50px;
  height: 50px;
}

.barcode-container {
  display: flex !important;
}
.barcode-container h6 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  text-align: left;
}

.barcode-container .row {
  width: 100% !important;
}

/*.jewellerycert-box {
    display: flex;
    justify-content: space-between;
}*/

.jewellerycert-box-left h5 {
  font-size: 13px;
  width: 74%;
  margin-bottom: 0px;
  padding-top: 45px;
  text-align: right;
}
.jewellerycert-box-right h5 {
  font-size: 13px;
  width: 74%;
  margin-bottom: 0px;
  padding-top: 45px;
  text-align: right;
}
.jewellerycert-lst ul li h6 {
  font-size: 11px;
  margin-bottom: 0px;
}

.jewellerycert-lst ul li p {
  font-size: 11px;
  margin-bottom: 0px;
}

.jewellerycert-lst ul {
  padding: 0px;
  margin: 0px;
  flex-wrap: wrap;
}
.jewellerycert-box-left {
  width: 50%;
  float: left;
  padding: 56px;
}
.jewellerycert-box-right {
  width: 50%;
  float: left;
  padding: 20px;
}

.jewellerycert-lst ul li {
  list-style: none;
  line-height: 15px;
  vertical-align: middle;
}

.jewellerycert-lst ul li {
  width: 50%;
}

.jewellerycert-lst {
  padding-top: 5px;
  position: relative;
}

.jewellerycert-lstrt ul {
  padding: 0px;
  margin: 0px;
  flex-wrap: wrap;
}

.jewellerycert-lstrt ul li {
  width: 50%;
  list-style: none;
  line-height: 15px;
  vertical-align: middle;
}
.jewellerycert-lstrt ul li:nth-child(odd) {
  width: 26%;
}
.jewellerycert-lstrt ul li:nth-child(even) {
  width: 74%;
}
.jewellerycert-lstrt ul li p {
  font-size: 11px;
  margin-bottom: 0px;
}

.jewellerycert-lstrt ul li h6 {
  font-size: 11px;
  margin-bottom: 0px;
}
.jewellerycert-lstrt {
  padding-top: 5px;
  position: relative;
}
.jewellerycert-lst ul li p img {
  width: 70px;
  height: 70px;
  position: absolute;
  right: 0px;
  bottom: 50px;
}
.jewellerycert-lstrt ul li p img {
  width: 70px;
  height: 70px;
  position: absolute;
  right: 0px;
  bottom: 50px;
}
