@page content-container {
  size: 21cm 29.7cm;
  margin: 30mm 45mm 30mm 45mm;
}

.content-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 4.5mm 0.6in;
  width: 100%;
  height: 100%;
  padding: 0.75in;
  padding-bottom: 2in;
}
.content {
  width: 100%; /* Adjusted width to fit 2 columns with gap */
  height: 3.8in; /* Adjusted height to fit 4 rows with gap */
  box-sizing: border-box;
  padding: 10px; /* Optional: Add padding to the grid items */
}

.content-summary {
  /* height: 100%; */
  display: flex;
  width: 100%;
  flex-direction: column;
}

.content-header {
  /* display: flex; */
  position: relative;
  width: 100%;
  height: 1.25in;
  /* height: 1.1in; */
  /* height: 75px; */
}
.content-header h5 {
  font-size: 16px;
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
}

.content-body {
  /* height: 2.8in; */
  /* height: 2.3in; */
  height: auto;
  width: 100%;
  position: relative;
}

.content-body img {
  width: 55px;
  position: absolute;
  right: 0;
  bottom: 0.3in;
}

.content-body h6 {
  font-size: 9px;
  margin-bottom: 0px;
  line-height: 13px;
}
.content-body p {
  font-size: 10px;
  margin-bottom: 0px;
}

.content-footer {
  height: 0.15in;
  width: 100%;
}

.content-body .row .col-3 {
  padding-right: 0;
  max-width: 23%;
}

.content-body .row .col-1 {
  padding: 0;
  max-width: 6%;
}

.content-body .row .col-8 {
  padding-left: 0px;
}

/**a4size**/


.certificate {
  margin: 0px 0px;
  background-color: unset;
  padding: 15px 0px;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 55px;
  padding-right: 55px;
  }
  .topcrt-header img {
      width: 150px;
      margin: 0 auto 20px;
      display: block;
  }
  .topcrt-header h4 {
      font-size: 18px;
      text-align: center;
      margin-bottom: 5px;
      font-weight: 600;
  }
  .topcrt-header h5 {
      text-align: center;
      font-size: 13px;
      margin-bottom: 5px;
      font-weight: 500;
  }
  .topcrt-header h3 {
font-size: 20px;
text-align: center;
font-weight: 700;
font-family: auto;
margin-top: 10px;
margin-bottom: 15px;
}
.topcrt-header h6 {
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 5px;
}
  .cert-box {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #e3cc42;
  padding: 5px 15px;
  /* background-color: #ffffff; */
  }
  .cert-box p {
      font-size: 16px;
      margin-bottom: 12px;
      font-weight: 600;
      width: 50%;
  }
  .labrt-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  border-bottom: 2px solid #e3cc42;
  padding: 5px 15px;
  }
  .labrt-box h6 {
      font-size: 16px;
      margin-bottom: 0px;
      width: 50%;
      font-weight: 600;
  }
  .descrpt-box {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 13px;
  padding-right: 13px;
  }
  .descrpt-box h6 {
      width: 430px;
      font-size: 16px;
      margin-bottom: 10px;
      margin-top: 10px;
      font-weight: 600;
  }
  .descrpt-box h5 {
  margin-bottom: 0px;
  font-size: 18px;
  visibility: hidden;
  }
.gross-wt ul li {
list-style: none;
width: 50%;
line-height: 20px;
float: left;
font-family: auto;
}
  .gross-wt {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 13px;
  padding-right: 13px;
  /* height: 500px; */
  height: auto;
  height: 350px;
  }
  .gross-wt ul {
  padding: 0px;
  margin: 0px;
  width: 700px;
  }
  .gross-wt-right {
  width: 325px;
  }
  .gross-wt-right img {
width: 75%;
margin: 0 auto;
display: block;
}
.gross-wt-right h5 {
text-align: center;
margin-top: 10px;
font-size: 16px;
}
  .gross-wt ul li h6 {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 600;
  }
  .gross-wt ul li p {
margin-bottom: 0px;
font-size: 15px;
}
  .clarity-box {
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
  border-bottom: 6px solid #ffffff;
  }
  .clarity-box p {
  margin-bottom: 0px;
  }
  .clarity-box h6 {
  margin-bottom: 0px;
  width: 83px;
  }
  .color-grade {
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
  border-bottom: 6px solid #ffffff;
  }
  .color-grade p {
  margin-bottom: 0px;
  }
  .color-grade h6 {
  margin-bottom: 0px;
  width: 115px;
  }
  .shape-box ul {
  display: flex;
  justify-content: space-between;
  }
  .shape-box ul {
  margin: 0px;
  padding: 0px;
  }
  .shape-box ul li {
  list-style: none;
  text-align: center;
  }
  .shape-box {
  padding: 5px 15px;
  border-bottom: 6px solid #ffffff;
  }
  .shape-box ul li p {
  margin-bottom: 0px;
  }
  .weightclc ul {
padding: 0px;
margin: 0px;
display: flex;
flex-wrap: wrap;
}
  .weightclc ul li {
  list-style: none;
  border: 1px solid;
  padding: 2px 4px;
  line-height: 25px;
  border-bottom: none;
  border-left: none;
  }
 .weightclc ul li p {
margin-bottom: 0px;
font-size: 12px;
line-height: 15px;
padding-top: 3px;
}
  .weightclc ul li h6 {
margin-bottom: 0px;
line-height: 23px;
/* width: 163px; */
font-size: 12px;
}
  .weightclc {
  padding: 5px 15px;
  padding-bottom: 50px;
  height: 400px;
  }
  /* .secuty-bx {
  background-color: #ffffff;
  padding: 15px 15px;
  } */
  .secuty-bx img {
  display: block;
  margin: 0 auto 3px;
}
.secuty-bx p {
  font-size: 11px;
  text-align: center;
  padding: 5px 0px;
  line-height: 11px;
  color: #afadad;
  border-top: 1px solid #000;}
  
  /* width: 270px; */
  /* margin: 0 auto; */
/*}

  .secuty-bx h4 {
font-size: 24px;
text-align: center;
font-family: none;
font-style: italic;
margin-bottom: 0px;
border-bottom: 1px solid #000;
padding-bottom: 58px;
}
  .secuty-bx h5 {
  text-align: center;
  font-weight: 400;
  margin-bottom: 0px;
  padding-top: 15px;
  font-size: 20px;
  }
  
  .footer-crt p {
margin-bottom: 0px;
font-size: 10px;
line-height: 10px;
text-align: justify;
}

.footer-crt {
padding: 0px 15px;
padding-top: 10px;
border-top: 2px solid #e3cc42;
clear: both;

}

.footer-crt p:last-child {
border-top: 1px solid #000;
padding-top: 6px;
margin-top: 5px;
}

.weightclc span {
font-size: 10px;
padding-left: 140px;
padding-bottom: 5px;
display: block;
line-height: 16px;
}

.weightclc ul li:nth-child(odd) {
width: 20%;
}
.weightclc ul li:nth-child(even) {
width: 80%;
border-left: none;
border-right: none;
}

.secuty-bx-left {
float: left;
width: 42%;
text-align: center;
padding-top: 20px;
}
.secuty-bx-center {
width: 16%;
float: left;
visibility: hidden;
}
.secuty-bx-right {
float: left;
width: 42%;
}

.weightclc h5 {
  font-size: 17px;
  border-top: 1px solid #000;
  margin: 0px;
  padding-top: 3px;
  padding-bottom: 5px;
  font-weight: 600;
}


.secuty-bx {
  padding-bottom: 50px;
  display: flow-root;
}




/**dosser**/
.gold-leaf {
  background-color: #fff;
  width: 100%;
  display: none;
  /* padding: 20px;*/
/* display: inline-block;
  display: block;
  height: 100%;
}

.artical-box-left {
  /* float: left; */
 /* padding: 10px;
  padding-top: 50px;
  /* padding-left: 0px; */
}

.artical-box-center {
  text-align: center;
  padding: 10px;
}

.artical-log img {
  width: 200px;
  margin: 0 auto 30px;
  display: block;
}

.rprts ul {
  margin: 0px;
  padding: 0px;
  display: block;
}

.rprts ul li {
  list-style: none;
  margin: 10px 0px;
  font-size: 15px;
  line-height: 20px;
}

.art-desct p {
  font-size: 14px;
  line-height: 18px;
}

.art-list ul {
  padding: 0px;
  margin: 0px;
  display: block;
}

.art-list ul li {
  list-style: none;
}

.Approx-img-left {
  float: left;
  width: 50%;
  text-align: left;
  visibility: hidden;
}

.Approx-img-right {
  float: right;
  width: 50%;
  text-align: right;
}

.Approx-img {
  width: 100%;
  display: block;
  padding: 0px 25px;
}

.Approx-img-left img {
  width: 60px;
}

.Approx-img-right img {
  width: 60px;
}

.art-list ul li span {
  text-align: right;
  float: right;
}

.jewellerycert-lst ul {
  padding: 0px;
  margin: 0px;
  display: inline-block;
  width: 100%;
}

.jewellerycert-lst ul li {
  list-style: none;
  line-height: 20px;
  vertical-align: middle;
  float: left;
  width: 50%;
}

.jewellerycert-lst ul li h6 {
  font-size: 14px;
  margin-bottom: 0px;
  float: left;
  font-weight: 400;
  line-height: 18px;
}

.artical-box-right {
  width: 55%;
  padding-top: 49px;
}

.artical-box-center img {
  width: 30%;
}
.artical-box-right .artical-log img {
  width: 150px;
  margin: 0 auto 30px;
  display: block;
}

.artical-box-right .rprts ul li {
  list-style: none;
  margin: 10px 0px;
  font-size: 13px;
  line-height: 20px;
}

.artical-box-right .art-desct h4 {
  font-size: 16px;
}

.artical-box-right .art-desct p {
  font-size: 12px;
  line-height: 20px;
}

.artical-box-right .jewellerycert-lst ul li h6 {
  font-size: 12px;
  margin-bottom: 0px;
  float: left;
  font-weight: 400;
  line-height: 17px;
  display: block;
}

.artical-box-center h5 {
  margin-bottom: 50px;
  font-size: 18px;
}

.artical-box-center h4 {
  padding-top: 74px;
  font-size: 18px;
}

.gold-leaf .container {
  margin: 0px;
  padding: 0px;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.gold-leaf .row {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
}

.gold-leaf .col-md-2 {
  flex: none;
  width: 100%;
  max-width: 100%;
}
.gold-leaf .col-md-5 {
  flex: none;
  width: 100%;
  max-width: 100%;
} 



.dosser {
  size: landscape!important;
}



.gold-leaf:first-of-type {
display: block;
}

