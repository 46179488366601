.tb-slct select {
  width: 165px;
  margin-top: 14px;
}
.horizontal-menu
  .top-navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-profile-img
  h5 {
  background-color: #ccc;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 25px;
  text-align: center;
  padding: 8px 14px;
  margin-bottom: 0px;
}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  top: 0px;
  right: 0;
  background-color: #d9a91d;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 45px;
}
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.sidenav a:hover {
  color: #f1f1f1;
}
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 28px;
  margin-left: 50px;
}
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.add-btns {
  padding: 10px 40px;
  background: linear-gradient(to right, #d9a91d, #d9a91d87) !important;
  border-radius: 0.1875rem;
  color: #fff;
  font-size: 0.875rem;
  line-height: 1;
  font-family: "ubuntu-bold", sans-serif;
  cursor: pointer;
}
